<template>
    <section class="section">
        <div class="container has-text-centered">
            <h1 class="title has-text-danger">Subscription Cancelled</h1>
            <p class="subtitle">It seems you have cancelled the subscription process. You can try again if you wish.</p>
            <a href="/pricing" class="button is-primary">Go Back to Pricing</a> <br>
            <a href="/dashboard" class="button is-danger">Go Back to Dashboard</a>

        </div>
    </section>
</template>

<script>
export default {
    name: 'CancelComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>